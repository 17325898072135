import React from "react";
import { AppBar } from "@mui/material"
import { Toolbar } from "@mui/material"
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { Menu, MenuItem } from "@material-ui/core";


import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1
    },
    menuButton: {
        marginLeft: 2
      },
    title: {
      flexGrow: 1
    }
  }));

function NavBar() {
    const classes = useStyles();
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleMenu = (event) => {
        setAnchor(event.currentTarget);
      };
    return(
        <AppBar 
        position="fixed"
        className="navbar-style">
          <Toolbar disableGutters={true}>
            <IconButton
              disabled="true"
              color="inherit"
              className = {classes.menuButton}
              edge = "start"
              aria-label="menu"
              onClick={handleMenu}
            >

            {/*<MenuIcon />*/}
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                KeepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                open={open}
                >
                <MenuItem
                    onClick={() => setAnchor(null)}
                >
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                      <Typography variant="h6"> Hjem </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => setAnchor(null)}
                >
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                      <Typography variant="h6"> Om Oss</Typography>
                </MenuItem>
                <MenuItem
                      onClick={() => setAnchor(null)}
                >
                    <ListItemIcon>
                    <GroupIcon />
                      </ListItemIcon>
                      <Typography variant="h6"> Teams </Typography>
                </MenuItem>
            </Menu>
            
            <Typography variant="h6" color="inherit">
              Samhandling.org
            </Typography>
          </Toolbar>
        </AppBar>
       
    );
}
export default NavBar;