import './App.css'
import NavBar from './components/NavBar.js'
// import Cards from './components/Cards.jsx'
import Image from './components/Image.jsx'
import TextContent from './components/TextContent.jsx'
import Footer from './components/Footer.jsx'
import { Divider } from '@mui/material';

function App() {
  return (
    <div className="App">
      <header className="App">
        <NavBar/>
        <Image />
        <Divider />
        <TextContent />
        <Footer />
        {/*<Cards />
        <Divider />
        */}
      </header>
    </div>
    
  );
}

export default App;
