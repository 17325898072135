import { Container, Typography, Button } from "@mui/material";
import React from "react";
import LinkIcon from '@mui/icons-material/Link';


function TextContent() {
    return (
    <div className="text-container">
        <Container>
            <Typography>
                <h2 className="h2-style">
                    Velkommen til vår fylkeskommunale samarbeidsarena
                </h2>
            </Typography>
            <Typography>
                <div className="center-textbox">
                    <div className="text-box-container">
                        På denne siden finner du blant annet informasjon om tilganger og dokumentasjon på oppsett som er nødvendig for å ta i bruk 
                        samarbeidsarenaen som er etablert i Microsoft Teams. 
                        <br/>
                    </div>
                </div>
                <br/>
                        Herfra kan du klikke deg videre til Microsoft Teams. 
                        <br/>
                <div className="button-style">
                    <Button variant="contained" 
                    href="https://teams.microsoft.com/_?tenantId=6040df17-d955-4746-ab23-4ba0356ea02e#"
                    >
                    <LinkIcon/> 
                    Link til Teams 
                    </Button>
                </div>
                <br/>
                    Mangler du tilgang? Da kan du kontakte IT-funksjonen i din i egen fylkeskommune.
                <br/>
                <Typography>
                    <h4>For IT-funksjonen i fylkeskommunen</h4>
                    <Typography>
                        <div className="center-textbox">
                            <div className="text-box-container">
                            <p><span style={{color: 'red'}}>MERK: </span>Fra og med 1.1.2023 vil basic auth som blir brukt i dagens løsning avvikles, dette betyr at det må gjøres en jobb i hver enkelt fylkeskommune for å gå over til den nye løsningen.
                            Dokumentasjon og en illustrativ guide på hvordan dette gjøres kan du se ved å navigere til Vestfold fylkeskommune sin github ved å trykke på knappen under.
                        </p>
                        </div>
                    </div>
                    </Typography>
                    <br/>
                </Typography>
                <div className="center-textbox">
                    <div className="text-box-container">
                        Last ned eller klon ned repoet fra Github.
                        Det er IT-funksjonen i hver enkelt fylkeskommune som står for oppsettet i samarbeid med Vestfold fylkeskommune.  
                        Oppsettet gjør det mulig for hver enkelt fylkeskommune å selv administrere hvem som gis tilgang til 
                        teamene som ligger på vår felles samarbeidsarena.
                    </div>
                </div>

                {/* <div className="button-style"> 
                    <Button variant="contained" href={samhandlingfil} download>
                        <DownloadIcon/>
                        Last ned fil
                    </Button>
                </div> */}
                <br/>
                <div className="button-style">
                    <Button variant="contained" 
                    href="https://github.com/vtfk/samhandling-invitescript"
                    >
                    <LinkIcon/> 
                    Link til Github 
                    </Button>
                </div>
            </Typography>
        </Container>
    </div>
    )
}

export default TextContent;