import React from "react";
// import samhandling from '../assets/samhandling2.jpg'
import fylkesvåpen  from '../assets/edit_standard_fylkesvapen.png'
import { Container } from "@mui/material";

function Header() {
    return(
        <Container>
        <div className="image-container">
            {/*<img src={samhandling} alt="Samhandling" />*/}
            <img src={fylkesvåpen} alt="Fylkesvåpen" />
        </div>
        </Container>
    );
  }
  
  export default Header;