import React from "react";
import { Grid, Container, Typography } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";

import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);



function Footer() {
    return (
        <div className="footer-style">
            <Container>
                <Grid  
                className="grid-items-style" 
                container spacing={2} 
                sx={{ flexGrow: 1 }} 
                xs={12} >
                    <HomeIcon 
                    sx={{ display: { xs: 'none', sm: 'block' } }} 
                    style={ {color: 'white'} } />
                    <WhiteTextTypography 
                    paddingLeft={2} 
                    paddingRight={2}>Vertsfylke er Vestfold fylkeskommune </WhiteTextTypography>
                    <ContactMailIcon 
                    sx={{ display: { xs: 'none', sm: 'block' } }} 
                    style={ {color: 'white'} } />
                    <WhiteTextTypography 
                    paddingLeft={2}> Kontakt: <a href="mailto:servicedesk@vestfoldfylke.no"> servicedesk@vestfoldfylke.no </a></WhiteTextTypography>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer;